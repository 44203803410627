var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _vm._v(
                  _vm._s(
                    this.routeSign === "add"
                      ? _vm.$t("ADD_ISSUE_SYSTEM")
                      : this.routeSign === "edit"
                      ? _vm.$t("EDIT_ISSUE_SYSTEM")
                      : _vm.$t("VIEW_ISSUE_SYSTEM")
                  ) + "\n            "
                )
              ]
            )
          ]),
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "refForm",
                    attrs: {
                      model: _vm.formObj,
                      rules: _vm.formObjRules,
                      "label-width": "120px",
                      "label-position": "top"
                    }
                  },
                  [
                    false
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "工单编号",
                              prop: "issueCode"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "calc(100% - 115px)" },
                              model: {
                                value: _vm.formObj.issueCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.formObj, "issueCode", $$v)
                                },
                                expression: "formObj.issueCode"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "120px",
                                  label: "问题类型",
                                  prop: "issueType"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    model: {
                                      value: _vm.formObj.issueType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formObj, "issueType", $$v)
                                      },
                                      expression: "formObj.issueType"
                                    }
                                  },
                                  _vm._l(_vm.typeList, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "120px",
                                  label: "备注",
                                  prop: "remark"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  model: {
                                    value: _vm.formObj.remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "remark", $$v)
                                    },
                                    expression: "formObj.remark"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "120px",
                                  label: "客户姓名",
                                  prop: "customerName"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  model: {
                                    value: _vm.formObj.customerName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "customerName", $$v)
                                    },
                                    expression: "formObj.customerName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "120px",
                                  label: "邮箱账号",
                                  prop: "emailAccount"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  model: {
                                    value: _vm.formObj.emailAccount,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "emailAccount", $$v)
                                    },
                                    expression: "formObj.emailAccount"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "120px",
                                  label: "区号",
                                  prop: "areaCode"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    model: {
                                      value: _vm.formObj.areaCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formObj, "areaCode", $$v)
                                      },
                                      expression: "formObj.areaCode"
                                    }
                                  },
                                  _vm._l(_vm.qhList, function(item) {
                                    return _c("el-option", {
                                      key: item.prefix,
                                      attrs: {
                                        label:
                                          item.country +
                                          "  (" +
                                          item.prefix +
                                          ")",
                                        value:
                                          item.country +
                                          "  (" +
                                          item.prefix +
                                          ")"
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "120px",
                                  label: "电话",
                                  prop: "phone"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  model: {
                                    value: _vm.formObj.phone,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "phone", $$v)
                                    },
                                    expression: "formObj.phone"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        false
                          ? _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "120px",
                                      label: "优先级",
                                      prop: "priority"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 115px)"
                                        },
                                        model: {
                                          value: _vm.formObj.priority,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formObj,
                                              "priority",
                                              $$v
                                            )
                                          },
                                          expression: "formObj.priority"
                                        }
                                      },
                                      _vm._l(_vm.options, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-wformDataidth": "120px",
                          label: "问题描述",
                          prop: "describe"
                        }
                      },
                      [
                        _c("quill-editor", {
                          ref: "myQuillEditor",
                          staticStyle: { width: "1200px", height: "200px" },
                          attrs: { options: _vm.editorOption },
                          on: {
                            blur: function($event) {
                              return _vm.onEditorBlur($event)
                            },
                            focus: function($event) {
                              return _vm.onEditorFocus($event)
                            },
                            change: function($event) {
                              return _vm.onEditorChange($event)
                            },
                            ready: function($event) {
                              return _vm.onEditorReady($event)
                            }
                          },
                          model: {
                            value: _vm.formObj.describe,
                            callback: function($$v) {
                              _vm.$set(_vm.formObj, "describe", $$v)
                            },
                            expression: "formObj.describe"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          padding: "50px 0"
                        }
                      },
                      [
                        this.routeSign !== "view"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitForm("refForm")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("Save")) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v(_vm._s(_vm.$t("Cancel")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        false
                          ? _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "120px",
                                      label: "工单状态",
                                      prop: "status"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      model: {
                                        value: _vm.formObj.status,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formObj, "status", $$v)
                                        },
                                        expression: "formObj.status"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }