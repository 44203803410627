<!-- 视图 -->
<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{
                    this.routeSign === 'add' ? $t('ADD_ISSUE_SYSTEM')
                    : this.routeSign === 'edit' ? $t('EDIT_ISSUE_SYSTEM') : $t('VIEW_ISSUE_SYSTEM')
                    }}
                </div>
            </div>
            <template>
                <div>
                    <el-form :model="formObj" ref="refForm" :rules="formObjRules" label-width="120px"
                             label-position="top">
                        <el-form-item label-width="120px" label="工单编号" prop="issueCode" v-if="false">
                            <el-input v-model="formObj.issueCode" style="width: calc(100% - 115px);"></el-input>
                        </el-form-item>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item label-width="120px" label="问题类型" prop="issueType">
                                    <el-select v-model="formObj.issueType" style="width: calc(100% - 115px);">
                                        <el-option
                                                v-for="item in typeList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item label-width="120px" label="备注" prop="remark">
                                    <el-input v-model="formObj.remark" style="width: calc(100% - 115px);"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item label-width="120px" label="客户姓名" prop="customerName">
                                    <el-input v-model="formObj.customerName"
                                              style="width: calc(100% - 115px);"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item label-width="120px" label="邮箱账号" prop="emailAccount">
                                    <el-input v-model="formObj.emailAccount"
                                              style="width: calc(100% - 115px);"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item label-width="120px" label="区号" prop="areaCode">
                                    <el-select v-model="formObj.areaCode" style="width: calc(100% - 115px);">
                                        <el-option
                                                v-for="item in qhList"
                                                :key="item.prefix"
                                                :label="item.country+'  ('+item.prefix+')'"
                                                :value="item.country+'  ('+item.prefix+')'">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item label-width="120px" label="电话" prop="phone">
                                    <el-input v-model="formObj.phone" style="width: calc(100% - 115px);"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" v-if="false">
                                <el-form-item label-width="120px" label="优先级" prop="priority">
                                    <el-select v-model="formObj.priority" style="width: calc(100% - 115px);">
                                        <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label-wformDataidth="120px" label="问题描述" prop="describe">
                            <quill-editor
                                    v-model="formObj.describe"
                                    ref="myQuillEditor"
                                    :options="editorOption"
                                    @blur="onEditorBlur($event)"
                                    @focus="onEditorFocus($event)"
                                    @change="onEditorChange($event)"
                                    @ready="onEditorReady($event)"
                                    style="width: 1200px;height: 200px"
                            >
                            </quill-editor>
                        </el-form-item>
                        <div style="text-align:right;padding:50px 0;">
                            <el-button type="primary" @click="submitForm('refForm')" v-if="this.routeSign!=='view'">
                                {{$t('Save')}}
                            </el-button>
                            <el-button @click="resetForm">{{ $t('Cancel') }}</el-button>
                        </div>
                        <!--查询菜单配置表-->
                        <!--                        <el-row class="el-row_style">-->
                        <!--                            <el-col :span="12" :sm="24" :md="12" >-->
                        <!--                                <el-form-item label-width="120px" label="父类菜单" prop="parentMenu">-->
                        <!--                                    <el-input v-model="formObj.parentMenu" style="width: calc(100% - 115px);"></el-input>-->
                        <!--                                </el-form-item>-->
                        <!--                            </el-col>-->
                        <!--                            <el-col :span="12" :sm="24" :md="12" >-->
                        <!--                                <el-form-item label-width="120px" label="子类菜单" prop="subclassesMenu">-->
                        <!--                                    <el-input v-model="formObj.subclassesMenu" style="width: calc(100% - 115px);"></el-input>-->
                        <!--                                </el-form-item>-->
                        <!--                            </el-col>-->
                        <!--                        </el-row>-->
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" v-if="false">
                                <el-form-item label-width="120px" label="工单状态" prop="status">
                                    <el-input v-model="formObj.status" style="width: calc(100% - 115px);"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!--                        <el-row class="el-row_style">-->
                        <!--                            <el-col :span="12" :sm="24" :md="12" >-->
                        <!--                                <el-form-item label-width="120px" label="服务人员" prop="personnelRole">-->
                        <!--                                    <el-input v-model="formObj.personnelRole" style="width: calc(100% - 115px);"></el-input>-->
                        <!--                                </el-form-item>-->
                        <!--                            </el-col>-->
                        <!--                            <el-col :span="12" :sm="24" :md="12" >-->
                        <!--                                <el-form-item label-width="120px" label="服务人员级别" prop="personnelLevel">-->
                        <!--                                    <el-input v-model="formObj.personnelLevel" style="width: calc(100% - 115px);"></el-input>-->
                        <!--                                </el-form-item>-->
                        <!--                            </el-col>-->
                        <!--                        </el-row>-->
                    </el-form>
                </div>
            </template>
        </basic-container>
    </div>
</template>
<!-- 样式 -->
<style>
    table th.star div::before {
        content: ' * ';
        color: red;
    }

    .el-row_style {
        display: flex;
        flex-wrap: wrap;
        height: 105px;
    }
</style>
<!-- 逻辑js -->
<script>
    //markdown
    import {quillEditor} from 'vue-quill-editor'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    //引入对象
    import {add, list, edit, QHList} from "@/api/issue/issue";
    import { mapGetters } from 'vuex'

    export default {
        components: {
            quillEditor
        },
        //data：返回实体对象
        data() {
            var validateEmail = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('负责人邮箱不可为空'));
                } else {
                    if (value !== '') {
                        var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                        if (!reg.test(value)) {
                            callback(new Error('请输入有效的邮箱'));
                        }
                    }
                    callback();
                }
            };
            var validateMobilePhone = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('负责人手机号不可为空'));
                } else {
                    if (value !== '') {
                        // var reg=/^1[3456789]\d{9}$/; //校验中国手机号
                        var reg = /^[0-9]*$/;//纯数字校验
                        if (!reg.test(value)) {
                            callback(new Error('请输入有效的手机号码'));
                        }
                    }
                    callback();
                }
            };
            var validateCustomerName = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入您的姓名'));
                }
                callback();
            };
            var validateIssueType = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请选择问题类型'));
                }
                callback();
            };
            var validateDescribe = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入问题描述'));
                }
                callback();
            };
            var validatePriority = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请选择优先级'));
                }
                callback();
            };
            return {
                content: '',
                html: '',
                configs: {},
                emailAccount: '',
                qhList:[],
                //路由传递信息
                routeSign: '', issueCode: '',
                //表单信息
                formObj: {
                    issueCode: '',
                    issueType: '',
                    remark: '',
                    customerName: '',
                    customerId: '',
                    emailAccount: '',
                    phone: '',
                    parentMenu: '',
                    subclassesMenu: '',
                    priority: '',
                    status: '',
                    responseBy: '',
                    personnelLevel: '',
                    personnelRole: '',
                    describe: '',
                    areaCode: '',
                },
                //校验
                formObjRules: {
                    phone: [{required: true, validator: validateMobilePhone, trigger: 'blur'}],
                    emailAccount: [{required: true, validator: validateEmail, trigger: 'blur'}],
                    customerName: [{required: true, validator: validateCustomerName, trigger: 'blur'}],
                    issueType: [{required: true, validator: validateIssueType, trigger: 'blur'}],
                    describe: [{required: true, validator: validateDescribe, trigger: 'blur'}],
                    priority: [{required: true, validator: validatePriority, trigger: 'blur'}],
                    areaCode: [{required: true,message: '请输入区号', trigger: 'blur'}],
                },
                //优先级下拉框
                options: [
                    {
                        label: 'low',
                        value: 0
                    },
                    {
                        label: 'middle',
                        value: 1
                    },
                    {
                        label: 'high',
                        value: 2
                    }
                ],
                //问题类型下拉框
                typeList: [
                    {
                        label: '业务分级咨询',
                        value: "业务分级咨询"
                    },
                    {
                        label: '技术咨询',
                        value: "技术咨询"
                    },
                    {
                        label: '用户数据信息咨询',
                        value: "用户数据信息咨询"
                    },
                ],
                // 富文本编辑器配置
                editorOption: {
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                            ['blockquote', 'code-block'], // 引用  代码块
                            [{header: 1}, {header: 2}], // 1、2 级标题
                            [{list: 'ordered'}, {list: 'bullet'}], // 有序、无序列表
                            [{script: 'sub'}, {script: 'super'}], // 上标/下标
                            [{indent: '-1'}, {indent: '+1'}], // 缩进
                            [{direction: 'rtl'}], // 文本方向
                            [{size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36']}], // 字体大小
                            [{header: [1, 2, 3, 4, 5, 6]}], // 标题
                            [{color: []}, {background: []}], // 字体颜色、字体背景颜色
                            // [{ font: ['songti'] }], // 字体种类
                            [{align: []}], // 对齐方式
                            ['clean'], // 清除文本格式
                            ['image', 'video'] // 链接、图片、视频
                        ]
                    },
                    placeholder: '例：我按照系统的表格模板填写，并且导入了表格，但是上传以后显示我有些字段是没有填写的，这个怎么处理？'
                },
            }
        },
        computed:{
            ...mapGetters('oidcStore', [
                "oidcUser"
            ])
        },
        created() {
            this.routeSign = this.$route.query.sign;
            this.issueCode = this.$route.query.issueCode;
            if (this.routeSign !== 'add') {//修改 && 查看
                this.editInfo();
            }
            this.selectQH();
        },
        mounted() {
            this.formObj.customerName = this.oidcUser.name
        },
        watch: {
            $route() {
                this.init()
            }
        },
        methods: {
            //初始加载数据
            init() {
            },
            //提交数据
            submitForm(formName) {
                this.routeSign = this.$route.query.sign;
                this.issueCode = this.$route.query.issueCode;
                console.log("this.formObj", this.formObj);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.routeSign === 'add') {
                            add(this.formObj).then((res) => {
                                this.request.post(res);
                                this.$message({
                                    type: "success",
                                    message: res.data.msg,
                                });
                                this.$router.push({path: '/help/issue'});
                            })
                        } else {
                            //修改
                            edit(this.formObj).then((res) => {
                                debugger
                                this.request.post(res);
                                this.$message({
                                    type: "success",
                                    message: res.data.msg,
                                });
                                this.$router.push({path: '/help/issue'});
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //查看
            editInfo() {
                this.routeSign = this.$route.query.sign;
                this.issueCode = this.$route.query.issueCode;
                const xx = {
                    pageNo: 1,
                    pageSize: 100,
                    total: 100,
                    issueCode: this.issueCode,
                };
                list(xx).then(res => {
                    const info = res.data.data.items;
                    this.formObj = info[0];
                }).catch(erro => {
                    console.log(erro);
                })
            },
            //取消按钮
            resetForm() {
                this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
                this.$router.push({path: '/help/issue'});
            },
            //获取区号
            selectQH() {
                const xx = {
                    pageNo: 1,
                    pageSize: 100,
                    total: 100,
                };
                QHList(xx).then(res => {
                    this.qhList = res.data.data.items;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            // 失去焦点事件
            onEditorBlur(quill) {
                console.log('editor blur!', quill)
            },
            // 获得焦点事件
            onEditorFocus(quill) {
                console.log('editor focus!', quill)
            },
            // 准备富文本编辑器
            onEditorReady(quill) {
                // toolbar标题
                const titleConfig = [
                    {Choice: '.ql-insertMetric', title: '跳转配置'},
                    {Choice: '.ql-bold', title: '加粗'},
                    {Choice: '.ql-italic', title: '斜体'},
                    {Choice: '.ql-underline', title: '下划线'},
                    {Choice: '.ql-header', title: '段落格式'},
                    {Choice: '.ql-strike', title: '删除线'},
                    {Choice: '.ql-blockquote', title: '块引用'},
                    {Choice: '.ql-code', title: '插入代码'},
                    {Choice: '.ql-code-block', title: '插入代码段'},
                    {Choice: '.ql-font', title: '字体'},
                    {Choice: '.ql-size', title: '字体大小'},
                    {Choice: '.ql-list[value="ordered"]', title: '编号列表'},
                    {Choice: '.ql-list[value="bullet"]', title: '项目列表'},
                    {Choice: '.ql-direction', title: '文本方向'},
                    {Choice: '.ql-header[value="1"]', title: 'h1'},
                    {Choice: '.ql-header[value="2"]', title: 'h2'},
                    {Choice: '.ql-align', title: '对齐方式'},
                    {Choice: '.ql-color', title: '字体颜色'},
                    {Choice: '.ql-background', title: '背景颜色'},
                    {Choice: '.ql-image', title: '图像'},
                    {Choice: '.ql-video', title: '视频'},
                    {Choice: '.ql-link', title: '添加链接'},
                    {Choice: '.ql-formula', title: '插入公式'},
                    {Choice: '.ql-clean', title: '清除字体格式'},
                    {Choice: '.ql-script[value="sub"]', title: '下标'},
                    {Choice: '.ql-script[value="super"]', title: '上标'},
                    {Choice: '.ql-indent[value="-1"]', title: '向左缩进'},
                    {Choice: '.ql-indent[value="+1"]', title: '向右缩进'},
                    {Choice: '.ql-header .ql-picker-label', title: '标题大小'},
                    {Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一'},
                    {Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二'},
                    {Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三'},
                    {Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四'},
                    {Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五'},
                    {Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六'},
                    {Choice: '.ql-header .ql-picker-item:last-child', title: '标准'},
                    {Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号'},
                    {Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号'},
                    {Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号'},
                    {Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准'},
                    {Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐'},
                    {Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐'},
                    {Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐'},
                    {Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐'}
                ];
                for (let item of titleConfig) {
                    let tip = document.querySelector('.quill-editor ' + item.Choice);
                    if (!tip) continue;
                    tip.setAttribute('title', item.title)
                }
                console.log('editor ready!', quill)
            },
            // 内容改变事件
            onEditorChange({quill, html, text}) {
                console.log('editor change!', quill, html, text);
                this.content = html
            },
        },
    }
</script>